// Change layout variables (with "!default" flag)
// Note: please only adjust Bootstrap variables, or make BS-compatible additions
//$enable-rounded: false !default;

$primary-faded : lighten($primary, 53%);
$theme-1: #F39200;
$theme-2: #1B9CD9;
$theme-3: #94C01F;
$theme-dark-1: #E16512;
$theme-dark-2: #436B93;
$theme-dark-3: #617A19;

$border-width: 1px;
$border-radius-lg: 12px;
$theme-colors: (
        'primary-faded': $primary-faded,
        'tertiary':    $tertiary,
        'theme-1':    $theme-1,
        'theme-2':    $theme-2,
        'theme-3':    $theme-3,
        'theme-dark-1':    $theme-dark-1,
        'theme-dark-2':    $theme-dark-2,
        'theme-dark-3':    $theme-dark-3,
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$theme-gradient-1: linear-gradient(90deg, rgba(243,146,0,1) 0%, rgba(225,101,18,1) 100%);
$theme-gradient-2: linear-gradient(90deg, rgba(27,156,217,1) 0%, rgba(67,107,147,1) 100%);
$theme-gradient-3: linear-gradient(90deg, rgba(148,192,31,1) 0%, rgba(97,122,25,1) 100%);

$border-radius: 0.15rem;
$btn-border-radius: 0.15rem;
$btn-border-radius-sm: 0.15rem;
$btn-border-radius-lg: 0.15rem;

$font-family-base: 'poppins', sans-serif;
$headings-color: $primary;

@import '@/src/scss/main.scss';

form#CustomerForm-Login {
  small.text-muted a {
    color: $text-muted;
  }
}

.border-info {
  border: 2px solid $info;
  border-radius: 12px;
}

footer {
  a {
    color: $white;
  }
}

.header--min-height {
  min-height: 350px;
  overflow-x: hidden;
}

.subhome {
  .input-warehouse--filter {
    display:none;
  }
  .input-date--filter {
    @extend .ml-auto;
  }
  .input-activity--filter {
    @extend .mr-auto;
  }
}

.hero-video--thumb img {
  border-radius: 0.25rem;
}

.hero-video--thumb {
  @extend .w-100;
}

//.hero-video--thumb:hover .play-overlay {
//  @extend .w-100;
//  @extend .h-100;
//  top: calc(50% - 50px);
//  left: calc(50% - 50px);
//}

.play-overlay {
  background: #0000008f;
  color: white;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 35px);
  width: 70px;
  height: 70px;
  left: calc(50% - 35px);
  transition: all 0.3s;
}

.play-overlay i {
  position: relative;
  left: 50%;
  transform: translate(-40%, -60%);
  top: 50%;
}

div.photo-banner {
  position: relative;
  top: 0;
  left: 0;

  #bottom-layer {
    position: relative;
    top: 0;
    left: 0;
    max-height: 650px;
    min-height: 250px;
    width: 100%;
  }

  #top-layer {
    position: absolute;
    bottom: -2px;
    left: 0;
    max-height: 150px;
    width: 100%;
  }
}

div.social-icons {
  margin-left: 10px;
  display: inline-block;

  i {
    &:hover {
      color: $primary !important;
    }
  }
}


.top-menu {
  color: $dark;
  font-weight: 400;
  font-size: 16px;

  ul {
    margin-bottom: 0;

    li {
      margin: 10px 10px 10px 10px;
      list-style: none;

      a {
        color: $dark;
        text-decoration: none;

      }

      .active {
        a {
          color: $info;
        }
      }
    }
  }
}

.social-icons {
  a {
    border: solid 1px;
    width: 24px;
    padding: 2px;
    i {
      width: 100%;
    }
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: $white;
}

.nav-cart {
  @extend .position-relative;
  .nav-link {
    @extend .px-lg-3;
    &.disabled {
      .badge {
        display: none;
      }
    }
  }
  .badge {
    @extend .ml-2, .ml-lg-0;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 8px;
      right: 0;
    }
  }
}
